<template>
  <div class="instructions-page-wrapper content-container">
    <PageTitle :title="$t('INSTRUCTIONS.TITLE')" class="page-title instructions-title" />

    <!-- Left part of the page - Texts -->
    <div class="instructions-texts">
      <!-- Top Instructions -->
      <p class="page-section-text section-text">
        {{ $t('INSTRUCTIONS.INTRO') }}
      </p>

      <!-- Instructions -->
      <div class="manual">
        <AccordionItem v-for="item in instructions" :key="item.id" :title="item.title" :text="item.content"
          class="manual-item" />
      </div>

      <!-- Discover -->
      <div class="discover">
        <h1 class="page-section-title section-title">{{ $t('INSTRUCTIONS.DISCOVER') }}</h1>
        <p class="page-section-text section-text">
          {{ $t('INSTRUCTIONS.DISCOVER_TEXT') }}
        </p>
      </div>

      <div class="divider"></div>

      <!-- Problem? -->
      <h1 class="page-section-title section-title">{{ $t('INSTRUCTIONS.PROBLEM') }}</h1>
      <p class="page-section-text section-text">
        {{ $t('INSTRUCTIONS.PROBLEM_BEFORE_LINK') }}
        <router-link :to="{ name: getCorrectLink(PAGES.HELP) }" class="link-main">{{ $t('HELP_TITLE') }}</router-link>
        {{ $t('INSTRUCTIONS.PROBLEM_AFTER_LINK') }}<a class="link-main"
          href="mailto:info@mediamasters.nl">info@mediamasters.nl</a>.
      </p>
    </div>

    <!-- Right part of the page - Downloadables and video links -->
    <div class="downloads-videos-boxes">

      <!-- Manual -->
      <InformationBox custom-class="instruction-box" :display-icon="false">
        <template #text>
          <p class="box-title">{{ $t('INSTRUCTIONS.MANUAL_TITLE') }}</p>
          <p class="info-box">{{ $t('INSTRUCTIONS.MANUAL') }}</p>
          <ul class="sources-list">
            <!-- Manual download here -->
            <a class="link-item link-main" @click="downloadItem('/uploads/Handleiding_MediaMasters_2024_voorbereiden.pdf', 'Handleiding_MediaMasters_2024_voorbereiden.pdf')">{{
                formatFilename('Handleiding_MediaMasters_2024_voorbereiden.pdf') }}</a>
          </ul>
        </template>
      </InformationBox>

      <!-- Puzzles -->
      <InformationBox v-if="attachments?.puzzle && attachments.puzzle.length > 0"
        custom-class="instruction-box" :display-icon="false">
        <template #text>
          <p class="box-title">{{ $t('INSTRUCTIONS.PUZZLES') }}</p>
          <ul class="sources-list">
            <li v-for="item in attachments.puzzle" :key="item.id">
              <a class="link-item link-main" @click="downloadItem(item.mediaFileUrl, item.name)">{{
                formatFilename(item.name) }}</a>
            </li>
          </ul>
        </template>
      </InformationBox>

      <!-- Downloads -->
      <InformationBox v-if="attachments?.download && attachments.download.length > 0"
        :custom-class="'instruction-box'" :display-icon="false">
        <template #text>
          <p class="box-title">{{ $t('DOWNLOADS') }}</p>
          <p class="info-box">Hier vind je handige downloads zoals de brief voor ouders, de poster en het certificaat
            van deelname voor leerlingen.</p>
          <ul class="sources-list">
            <li v-for="item in attachments.download" :key="item.id">
              <a class="link-item link-main" @click="downloadItem(item.mediaFileUrl, item.name)">{{
                formatFilename(item.name) }}</a>
            </li>
          </ul>
        </template>
      </InformationBox>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import AccordionItem from '@/components/elements/accordion/AccordionItem'
import { manualItems } from '@/utils/data/manualItems'
import InformationBox from '@/components/elements/InformationBox'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import { GET_INSTRUCTIONS_PAGE } from '@/store/modules/teacherPortal/actions'
import Axios from 'axios'
import { getCorrectLink, PAGES } from '@/utils/helpers/RouterSelector'
import VideoPlayer from '@/components/elements/video/VideoPlayer'

export default {
  name: 'Instructions',
  components: {
    InformationBox,
    AccordionItem,
    PageTitle,
    VideoPlayer,
  },
  setup() {
    const store = useStore()
    const instructionPage = computed(() => store.getters.getInstructionsPage)
    const instructions = computed(() => instructionPage.value.instructions || [])
    const videos = computed(() => instructionPage.value.videos || [])
    const attachments = computed(() => instructionPage.value.attachments || [])



    function downloadItem(url, label) {
      const fullUrl = url.startsWith('http') ? url : process.env.VUE_APP_ROOT_API + url
      Axios.get(fullUrl, { responseType: 'blob' })
        .then(response => {
          console.log(response)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    }


    function formatFilename(fileName, addEnd = true) {
      const n = fileName.lastIndexOf('.')
      const result = fileName.substring(n + 1)
      const beginning = fileName.substring(0, n).replaceAll('_', ' ')

      if (addEnd) {
        return `${beginning} (${result.toUpperCase()})`
      } else {
        return beginning
      }
    }

    store.dispatch(GET_INSTRUCTIONS_PAGE)
    // TODO: Add translation string.

    return {
      ROUTE_NAMES_TEACHER_PORTAL,
      manual: manualItems,

      instructions,
      attachments,
      videos,

      downloadItem,
      formatFilename,

      getCorrectLink,
      PAGES,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

$left-part-width: 621px;

p.info-box {
  color: white;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: rem(10);
}

.instructions-page-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.instructions-title {
  flex-basis: 100%;
}

/** Right part of the page - Downloadables and video links **/
.downloads-videos-boxes {
  padding-left: rem(70);
  width: calc(100% - #{$left-part-width});

  .box-title {
    color: white;
    font-size: rem(24);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(28);
  }
}

.sources-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: rem(16);
  padding-inline-start: 0;

  .link-item {
    color: white;
    text-decoration: underline;
    line-height: rem(26);
    font-weight: 100;
    cursor: pointer;

    // Add the custom color bullet.
    &::before {
      vertical-align: middle;
      content: "\2022";
      color: white;
      font-size: rem(21);
      font-weight: 400;
      display: inline-block;
      width: rem(6);
      margin-right: rem(20);
    }
  }
}

/** **/

/** Left part of the page - Instructions/Texts **/
.instructions-texts {
  width: $left-part-width;

  .divider {
    background-color: var(--blue_light);
    border: none;
    height: 1px;
    opacity: 30%;
    margin: rem(40) 0;
  }
}

.pay-attention {
  margin-top: rem(25);
}

.section-title {
  margin-top: rem(40);
}

/** **/

/** Manual Accordion **/
.manual {
  margin-top: rem(20);

  .manual-item {
    //&:not(:last-of-type) {
    margin-bottom: rem(12);
    //}
  }
}

/** **/
</style>
